import React from "react"

// Libraries
import { Link } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

// Icons
import Logo from "assets/icons/logo-tm.inline.svg"

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1100px;
`

const StyledFooter = styled.footer`
  padding: 20px 0;
  background-color: ${colors.charcoal};
  color: ${colors.white};

  ${breakpoint.medium`
    padding: 54px 0;
  `}

  .msg-error {
      font-size: 17px;
      font-weight: 500;
      color: ${colors.orange};
      padding-left: 12px!important;
  }

    
  .footer__column {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &--sitemap a:hover {
      color: ${colors.orange};
    }

    &--logo {
  		margin-left: -12px;
  
    svg {
      * {
        fill: ${colors.white};
        max-width: 240px;
        margin-left: -12px;
      }
    }
  }    

    ${breakpoint.medium`
		width: 70%;

      &--logo {
        width: 250px;
		    margin-top: 5px;
		    margin-left: 25px;
      }

      &--sitemap {
        order: 0;
      }
            
    `}

    .title {
      color: ${colors.purpleLight};
      margin-bottom: 10px;
    }

    .footer__copy {
      margin-top: 1rem;
      font-size: 0.7rem;
      line-height: 1.1rem;
    }

  }




  ul {
 	display: block;

	  ${breakpoint.medium`
		  display: inline-flex;
	  `}

  }
  
  li {
  	font-size: 17px;
		margin-right: 50px;
  }
`

class Footer extends React.Component {
  render = () => (
    <StyledFooter>
      <StyledContainer>      
        <div className="footer__column footer__column--logo">
          <Logo />
        </div>
        <div className="footer__column footer__column--sitemap">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/sexual-health-test/">Sexual Health Test</Link>
          </li>
          <li>
            <Link to="/faq/">FAQ</Link>
          </li>
          <li>
            <Link to="/legal/">Legal</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
        </ul>
        <p className="footer__copy">©{new Date().getFullYear()} Visby Medical, Inc. All&nbsp;rights&nbsp;reserved. 
          Visby&nbsp;Medical and the Visby&nbsp;Medical logo are registered trademarks of Visby&nbsp;Medical, Inc.</p>
	      </div>
      </StyledContainer>
    </StyledFooter>
  )
}

export default Footer
